var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container", style: { height: _vm.height - 90 + "px" } },
    _vm._l(_vm.items, function (item, index) {
      return _c("div", { key: index, staticClass: "content-item" }, [
        _c("div", [_vm._v(_vm._s(item.time))]),
        _c("div", [
          _vm._v(
            " " +
              _vm._s(
                item.vehicleNo +
                  (item.innerId ? "-" + item.innerId : "") +
                  (item.vehicleName ? "-" + item.vehicleName : "")
              ) +
              " " +
              _vm._s(item.address) +
              " "
          ),
        ]),
        _c("div", [_vm._v("接管原因：" + _vm._s(item.reason))]),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _c("div", { staticClass: "content" }, [
      _c("div", { staticClass: "value" }, [
        _vm._v(
          _vm._s(_vm.yearOdo ? (Number(_vm.yearOdo) / 1000).toFixed(2) : 0) +
            "KM"
        ),
      ]),
      _c("div", { staticClass: "time" }, [
        _vm._v(
          _vm._s(_vm.beginDate) +
            " ~ " +
            _vm._s(_vm.endDate ? _vm.endDate : "至今")
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
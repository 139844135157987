<template>
  <div class="container" ref="containerRef"></div>
</template>
<script>
import * as echarts from 'echarts'
import { Empty } from 'ant-design-vue'
import { getChartInterval } from '@/utils/util'
export default {
  components: {
    AEmpty: Empty
  },
  props: {
    params: {
      type: Array,
      default: () => {}
    },
    resize: {
      type: Number,
      default: 0
    }
  },
  watch: {
    params(newVal, oldVal) {
      const isEmpty = !newVal || newVal.length === 0
      if (isEmpty) {
        this.myChart && this.myChart.dispose()
        this.myChart = null
        return
      }
      this.setOption(newVal)
    },
    resize(newVal, oldVal) {
      this.myChart && this.myChart.resize()
    }
  },
  data() {
    return {
      option: {
        title: {
          show: false,
          left: 'left',
          text: '每周活跃的智驾车数量',
          textStyle: {
            fontSize: 14
          }
        },
        tooltip: {
          formatter: '{c}辆'
        },
        xAxis: {
          type: 'category',
          data: [],
          axisLabel: {
            fontSize: 9
          }
        },
        yAxis: {
          type: 'value',
          minInterval: 1
        },
        series: [
          {
            data: [],
            type: 'bar'
          }
        ]
      }
    }
  },
  mounted() {
    this.loadChart()
  },
  methods: {
    setOption(data) {
      // const echarts = window.echarts
      // 判断图表库是否加载完成
      if (!echarts) {
        setTimeout(() => {
          this.setOption(data)
        }, 500)
        return
      }
      const weekArray = []
      const countArray = []
      let maxCount = 0
      if (data && data.length > 0) {
        data.forEach((p) => {
          weekArray.push(p.summaryDate)
          countArray.push(p.vehicleCount)
          if (p.vehicleCount > maxCount) {
            maxCount = p.vehicleCount
          }
        })
        weekArray.reverse()
        countArray.reverse()
        console.log('weekArray', weekArray)
      }
      this.option.yAxis.interval = getChartInterval(maxCount)
      this.option.xAxis.data = weekArray
      this.option.series[0].data = countArray

      if (!this.myChart) {
        this.myChart = echarts.init(this.$refs.containerRef)
      }
      this.option && this.myChart.setOption(this.option)
    },
    loadChart() {
      this.$nextTick(() => {
        this.$nextTick(() => {
          this.myChart = echarts.init(this.$refs.containerRef)
        })
      })
    }
  },
  beforeDestroy() {
    this.myChart && this.myChart.dispose()
    this.myChart = null
  }
}
</script>

<style lang="less" scoped>
.container {
  width: 100%;
  height: 100%;
}
</style>

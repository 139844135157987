<template>
  <div class="container">
    <div class="content">
      <div class="value">{{ yearOdo ? (Number(yearOdo) / 1000).toFixed(2) : 0 }}KM</div>
      <div class="time">{{ beginDate }} ~ {{ endDate ? endDate : '至今' }}</div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    yearOdo: {
      type: Number,
      default: 0
    },
    loading: {
      type: Boolean,
      default: true
    },
    beginDate: {
      type: String,
      default: () => {}
    },
    endDate: {
      type: String,
      default: () => {}
    }
  },
  data() {
    return {
      currentYear: new Date().getFullYear()
    }
  }
}
</script>
<style lang="less" scoped>
.container {
  margin-left: 13px;
  display: flex;
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .value {
      margin-top: 20px;
      font-size: 60px;
      font-weight: normal;
      color: #05ac9c;
    }
    .time {
      margin-top: 20px;
      font-size: 24px;
      border: 1px solid #05ac9c;
      border-radius: 10px;
      padding-left: 10px;
      padding-right: 10px;
      padding-bottom: 2px;
    }
  }
}
</style>

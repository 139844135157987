<!--
 * @Auth: linjituan
 * @Date: 2022-03-31 14:21:50
 * @LastEditors: linjituan
 * @LastEditTime: 2022-03-31 14:46:48
-->
<template>
  <div class="container" :style="{ height: height - 90 + 'px' }">
    <div v-for="(item, index) in items" :key="index" class="content-item">
      <div>{{ item.time }}</div>
      <div>
        {{
          item.vehicleNo + (item.innerId ? '-' + item.innerId : '') + (item.vehicleName ? '-' + item.vehicleName : '')
        }}
        {{ item.address }}
      </div>
      <div>接管原因：{{ item.reason }}</div>
    </div>
  </div>
</template>
<script>
import { Empty } from 'ant-design-vue'
export default {
  components: {
    AEmpty: Empty
  },
  props: {
    params: {
      type: Array,
      default: () => {}
    },
    height: {
      type: Number,
      default: () => {}
    }
  },
  watch: {
    params(newVal, oldVal) {
      this.setData(newVal)
    }
  },
  data() {
    return {
      items: []
    }
  },
  beforeCreate() {
    this.simpleImage = Empty.PRESENTED_IMAGE_SIMPLE
  },
  methods: {
    setData(data) {
      this.items = []
      if (!data || data.length === 0) {
        return
      }
      data.forEach(p => {
        const typeObj = p.vehicleEventSmallTypes.find(t => t.code === p.smallType)
        this.items.push({
          time: this.parseDateTime(new Date(p.time), 'yyyy-MM-dd HH:mm:ss'),
          vehicleNo: p.vehicleId,
          address: p.address,
          reason: typeObj ? typeObj.msg : ''
        })
      })
    }
  }
}
</script>
<style lang="less" scoped>
.container {
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 5px;
  height: 100%;
  padding: 0;
  overflow: auto;
  .content-item {
    padding: 2px 0;
    font-size: 16px;
    margin-bottom: 10px;
  }
}
// 设置滚动条样式
.container::-webkit-scrollbar {
  width: 4px;
  height: 10px;
}
.container:hover::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}
.container::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 10px;
}
.container::-webkit-scrollbar-track {
  border-radius: 10px;
}
</style>

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("page-header-wrapper", [
        _c(
          "div",
          { staticClass: "work-place" },
          [
            _c(
              "a-row",
              { attrs: { gutter: 24 } },
              [
                _c(
                  "a-col",
                  {
                    staticClass: "col",
                    staticStyle: {
                      "padding-left": "20px",
                      "padding-right": "20px",
                    },
                    attrs: { xl: 8, lg: 24, md: 24, sm: 24, xs: 24 },
                  },
                  [
                    _c(
                      "card-ex",
                      { attrs: { title: "期间累计智驾里程" } },
                      [
                        _c("miles", {
                          attrs: {
                            yearOdo: _vm.yearOdo,
                            loading: _vm.loading,
                            beginDate: _vm.beginDate,
                            endDate: _vm.endDate,
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "card-ex",
                      {
                        staticStyle: { "margin-top": "20px" },
                        attrs: {
                          title: "月MPD",
                          isEmpty:
                            !_vm.vehicleDataMonth ||
                            _vm.vehicleDataMonth.length === 0,
                          height: 360,
                        },
                      },
                      [
                        _c("MonthMPD", {
                          attrs: {
                            params: _vm.vehicleDataMonth,
                            resize: _vm.resize,
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "a-col",
                  {
                    staticClass: "col",
                    staticStyle: { "padding-left": "0", "padding-right": "0" },
                    attrs: { xl: 8, lg: 24, md: 24, sm: 24, xs: 24 },
                  },
                  [
                    _c(
                      "card-ex",
                      {
                        attrs: {
                          title: "每周智驾里程及运行时长",
                          isEmpty:
                            !_vm.vehicleDataWeek ||
                            _vm.vehicleDataWeek.length === 0,
                          height: 360,
                        },
                      },
                      [
                        _c("week-miles-time", {
                          attrs: {
                            params: _vm.vehicleDataWeek,
                            resize: _vm.resize,
                            loading: _vm.loading,
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "card-ex",
                      {
                        style: { marginTop: _vm.defautMargin },
                        attrs: {
                          title: "每周活跃的智驾车数量",
                          isEmpty:
                            !_vm.vehicleDataWeek ||
                            _vm.vehicleDataWeek.length === 0,
                          height: 360,
                        },
                      },
                      [
                        _c("week-vehicle-active-count", {
                          attrs: {
                            params: _vm.vehicleDataWeek,
                            resize: _vm.resize,
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "a-col",
                  {
                    staticClass: "col",
                    staticStyle: { "padding-left": "20px" },
                    attrs: { xl: 8, lg: 24, md: 24, sm: 24, xs: 24 },
                  },
                  [
                    _c(
                      "card-ex",
                      {
                        attrs: {
                          title: "每周平均无接管运行公里数",
                          isEmpty:
                            !_vm.vehicleDataWeek ||
                            _vm.vehicleDataWeek.length === 0,
                          height: 360,
                        },
                      },
                      [
                        _c("week-run-miles", {
                          attrs: {
                            params: _vm.vehicleDataWeek,
                            resize: _vm.resize,
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "card-ex",
                      {
                        style: { marginTop: _vm.defautMargin },
                        attrs: {
                          title: "接管记录",
                          isEmpty:
                            !_vm.vehicleEventData ||
                            _vm.vehicleEventData.length === 0,
                          height: 360,
                        },
                      },
                      [
                        _c("take-over-record", {
                          attrs: {
                            params: _vm.vehicleEventData,
                            loading: _vm.loading,
                            height: 360,
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "a-row",
              [
                _c(
                  "card-ex",
                  {
                    style: { marginTop: _vm.defautMargin },
                    attrs: { title: "于万智驾车足迹", height: _vm.innerHeight },
                  },
                  [
                    _c("foot-mark", {
                      attrs: {
                        params: _vm.vehicleCityData,
                        resize: _vm.resize,
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "work-place-setup" },
        [
          _c(
            "span",
            {
              directives: [
                {
                  name: "hasPermi",
                  rawName: "v-hasPermi",
                  value: ["tenant:iot:vehicle:dashboards:edit"],
                  expression: "['tenant:iot:vehicle:dashboards:edit']",
                },
              ],
              on: {
                click: function ($event) {
                  _vm.showDefaultConfigModal = true
                },
              },
            },
            [_vm._v("修改默认统计范围")]
          ),
          _c("a-divider", {
            directives: [
              {
                name: "hasPermi",
                rawName: "v-hasPermi",
                value: ["tenant:iot:vehicle:dashboards:edit"],
                expression: "['tenant:iot:vehicle:dashboards:edit']",
              },
            ],
            attrs: { type: "vertical" },
          }),
          _c("span", { on: { click: _vm.handleSetup } }, [_vm._v("设置")]),
        ],
        1
      ),
      _c(
        "vxe-modal",
        {
          staticClass: "my-modal",
          attrs: {
            title: "智驾车辆dashboard设置",
            width: "30%",
            height: "90%",
            "show-zoom": "",
            resize: "",
            "lock-view": false,
            mask: false,
            position: { top: "5%", left: "40%" },
          },
          on: { close: _vm.handleCloseLeftModal },
          model: {
            value: _vm.showModal,
            callback: function ($$v) {
              _vm.showModal = $$v
            },
            expression: "showModal",
          },
        },
        [
          _c("Search", {
            attrs: {
              cacheKey: _vm.cacheKey,
              mode: _vm.mode,
              fromDate: _vm.beginDate,
              toDate: _vm.endDate,
              treeData: _vm.treeData,
              vehicleIds: _vm.vehicleIds,
            },
            on: {
              ok: _vm.handleSearch,
              cancel: function ($event) {
                _vm.showModal = false
              },
            },
          }),
        ],
        1
      ),
      _c(
        "vxe-modal",
        {
          staticClass: "my-modal",
          attrs: {
            title: "智驾车辆dashboard默认设置",
            width: "30%",
            height: "96%",
            "show-zoom": "",
            resize: "",
            "lock-view": false,
            mask: false,
            position: { top: "2%", left: "40%" },
          },
          on: {
            close: function ($event) {
              _vm.showDefaultConfigModal = false
            },
          },
          model: {
            value: _vm.showDefaultConfigModal,
            callback: function ($$v) {
              _vm.showDefaultConfigModal = $$v
            },
            expression: "showDefaultConfigModal",
          },
        },
        [
          _c("default-config", {
            on: {
              ok: _vm.handleSearch2,
              cancel: function ($event) {
                _vm.showDefaultConfigModal = false
              },
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }